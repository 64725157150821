import axios from '../../../util/axios';
import { AxiosResponse } from 'axios';
import { ISearchFilterIdItemDto } from '../../../api/hub/hub.models.generated';
import { useQuery } from '@tanstack/react-query';
import {
  CATALOG_STALE_TIME,
  qkBrands,
  qkEcoScore,
  qkHergestellt,
  qkLabels,
  qkRohstoffHerkunft,
  qkSpecialAssortments,
  qkSupplierGroups,
  qkSpecialDiet,
  qkAllergen,
  qkFilterTkCount,
  qkFilterSalesOrderSetCount,
  qkFilterActionsCount,
  qkFilterNoveltiesCount,
  qkHwgs
} from './searchQueryKeys';
import { API } from './apiEndpoints';
import { defaultPageSize } from '../model/searchModel';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';
import { ISearchRequestDTOData } from '../../../api';

async function fetchHwgs(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_HWGS, searchRequest);
  return response.data;
}

async function fetchBrands(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_BRANDS, searchRequest);
  return response.data;
}

async function fetchRohstoffHerkunft(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_ROHSTOFFHERKUNFT, searchRequest);
  return response.data;
}

async function fetchHergestellt(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_HERGESTELLT, searchRequest);
  return response.data;
}

async function fetchEcoScore(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_ECOSORE, searchRequest);
  return response.data;
}

async function fetchLabels(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_LABELS, searchRequest);
  return response.data;
}

async function fetchSupplierGroups(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_SUPPLIERGROUPS, searchRequest);
  return response.data;
}

async function fetchSpecialDiets(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_SPECIALDIET, searchRequest);
  return response.data;
}

async function fetchSpecialAssortments(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_SPECIALASSORTMENTS, searchRequest);
  return response.data;
}

async function fetchAllergen(searchRequest: ISearchRequestDTOData): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchFilterIdItemDto[]>>(API.SEARCHFILTER_ALLERGEN, searchRequest);
  return response.data;
}

async function fetchTkCount(searchRequest: ISearchRequestDTOData): Promise<number> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<number>>(API.SEARCHFILTER_TK, searchRequest);
  return response.data;
}

export async function fetchHasPurchasedArticles(): Promise<boolean> {
  const response = await axios.get<boolean>(API.SEARCHFILTER_HAS_PURCHASED_ARTICLES);
  return response.data;
}

async function fetchNoveltiesCount(searchRequest: ISearchRequestDTOData): Promise<number> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<number>>(API.SEARCHFILTER_NOVELTIES, searchRequest);
  return response.data;
}

async function fetchSalesOrderSetCount(searchRequest: ISearchRequestDTOData): Promise<number> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<number>>(API.SEARCHFILTER_SALESORDERSET, searchRequest);
  return response.data;
}

async function fetchActionsCount(searchRequest: ISearchRequestDTOData): Promise<number> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<number>>(API.SEARCHFILTER_ACTIONS, searchRequest);
  return response.data;
}

export async function fetchOwnBrands(): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.get<ISearchFilterIdItemDto[]>(API.SEARCHFILTER_OWN_BRANDS);
  return response.data;
}

export async function fetchTgsSupplierGroups(): Promise<ISearchFilterIdItemDto[]> {
  const response = await axios.get<ISearchFilterIdItemDto[]>(API.SEARCHFILTER_TGS_SUPPLIERGROUPS);
  return response.data;
}

function createSearchFilterHook<T = ISearchFilterIdItemDto[]>(getItems: (searchRequest: ISearchRequestDTOData) => Promise<T>, mainQueryKey: string) {
  return (liveSearchRequest: ISearchRequestDTOData) => {
    const request = {
      ...liveSearchRequest,
      page: 0,
      pageSize: defaultPageSize
    };

    const filterItemsQuery = useQuery({
      queryKey: [mainQueryKey, request],
      queryFn: () => executeAsyncActionNew(async () => await getItems(request), false),
      placeholderData: (previousData) => previousData,
      staleTime: CATALOG_STALE_TIME
    });

    return filterItemsQuery;
  };
}

export const useHwgsFilter = createSearchFilterHook(fetchHwgs, qkHwgs);
export const useBrandsFilter = createSearchFilterHook(fetchBrands, qkBrands);
export const useRohstoffHerkunftFilter = createSearchFilterHook(fetchRohstoffHerkunft, qkRohstoffHerkunft);
export const useHergestelltFilter = createSearchFilterHook(fetchHergestellt, qkHergestellt);
export const useEcoScoreFilter = createSearchFilterHook(fetchEcoScore, qkEcoScore);
export const useLabelFilter = createSearchFilterHook(fetchLabels, qkLabels);
export const useSupplierGroupsFilter = createSearchFilterHook(fetchSupplierGroups, qkSupplierGroups);
export const useSpecialAssortmentsFilter = createSearchFilterHook(fetchSpecialAssortments, qkSpecialAssortments);
export const useSpecialDietFilter = createSearchFilterHook(fetchSpecialDiets, qkSpecialDiet);
export const useAllergenFilter = createSearchFilterHook(fetchAllergen, qkAllergen);
export const useTkFilter = createSearchFilterHook<number>(fetchTkCount, qkFilterTkCount);
export const useNoveltiesFilter = createSearchFilterHook<number>(fetchNoveltiesCount, qkFilterNoveltiesCount);
export const useSalesOrderSetFilter = createSearchFilterHook<number>(fetchSalesOrderSetCount, qkFilterSalesOrderSetCount);
export const useActionsFilter = createSearchFilterHook<number>(fetchActionsCount, qkFilterActionsCount);
