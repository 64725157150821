import React from 'react';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { Badge, Button, Dialog, DialogContent, DialogActions, Grow, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';

interface IIconButtonMenuProps {
  icon: JSX.Element;
  menu?: (close: (event: any) => void) => JSX.Element;
  showBadge?: boolean;
  placement?: PopperPlacementType;
  disabled?: boolean;
  id?: string;
  fullscreen?: boolean;
}

const IconButtonMenu = observer(({ icon, menu, showBadge, placement, disabled = false, id = '', fullscreen = false }: IIconButtonMenuProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<any>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  function handleClose(event: { target: any }) {
    if (anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  function renderMenu(menu: (close: (event: any) => void) => JSX.Element) {
    if (!fullscreen) {
      return (
        <Popper open={open} sx={{ zIndex: 9999999999 }} anchorEl={anchorEl.current} transition disablePortal placement={placement ? placement : 'bottom-start'}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper square>
                <ClickAwayListener onClickAway={handleClose}>{menu(handleClose)}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    } else {
      return (
        <Dialog open={open} fullScreen={true} onClose={handleClose}>
          <DialogContent sx={{ overflowY: 'auto', maxHeight: '90vh' }}>{menu(handleClose)}</DialogContent>
          <DialogActions>
            <Button fullWidth={true} onClick={() => setOpen(false)} variant={'contained'} color="primary" style={{ minWidth: 130 }}>
              {'Schliessen'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  return (
    <>
      <IconButton ref={anchorEl} disabled={disabled} href={''} color={showBadge ? 'primary' : 'default'} onClick={handleToggle} id={id} size="large">
        <Badge invisible={!showBadge} color="primary" variant="dot">
          {icon}
        </Badge>
      </IconButton>
      {!disabled && menu && renderMenu(menu)}
    </>
  );
});

export default IconButtonMenu;
