import { useQuery } from '@tanstack/react-query';
import { CATALOG_STALE_TIME, qkCatalogSearch, qkCatalogComplete } from './searchQueryKeys';
import { ICatalogItemDto } from '../../../api/hub/hub.models.generated';
import axios from '../../../util/axios';
import { API } from './apiEndpoints';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';
import { ISearchRequestDTOData } from '../../../api';

async function fetchCatalogComplete(): Promise<ICatalogItemDto[]> {
  const response = await axios.get(API.CATALOG_COMPLETE);
  return response.data.items;
}

async function fetchCatalogSearch(searchRequest: ISearchRequestDTOData): Promise<ICatalogItemDto[]> {
  const response = await axios.post<ISearchRequestDTOData, any>(API.CATALOG_SEARCH, searchRequest);
  return response.data.items;
}

export function useCatalogComplete() {
  const catalogQuery = useQuery({
    queryKey: [qkCatalogComplete],
    queryFn: () => executeAsyncActionNew(async () => await fetchCatalogComplete(), false),
    placeholderData: (previousData) => previousData,
    staleTime: CATALOG_STALE_TIME
  });

  const isLoading = catalogQuery.isLoading;
  const error = catalogQuery.error;
  const catalogItems = catalogQuery.data ?? [];
  return { isLoading, error, catalogItems };
}

export function useCatalogSearch(urlSearchRequest: ISearchRequestDTOData, showOnlyRoot: boolean) {
  const searchCatalogQuery = useQuery({
    queryKey: [qkCatalogSearch, urlSearchRequest],
    queryFn: () => executeAsyncActionNew(async () => await fetchCatalogSearch(urlSearchRequest), false),
    placeholderData: (previousData) => previousData,
    enabled: showOnlyRoot,
    staleTime: CATALOG_STALE_TIME
  });

  const catalogItems = (searchCatalogQuery?.data && searchCatalogQuery.data.toSorted((a: any, b: any) => b.count - a.count)) ?? [];
  return { catalogItems };
}
