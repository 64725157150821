import { useQuery } from '@tanstack/react-query';
import type { IArticleSpecialDeliveryDateInfoData } from '../../../api';
import { getArticleIndividualSpecialDeliveryDatesQueryKey } from './searchQueryKeys';

export function useArticleIndividualSpecialDeliveryDateInfo(mainArticleId: number) {
  const specialDeliverDateQuery = useQuery<IArticleSpecialDeliveryDateInfoData, Error>({
    // Dieser Hook gibt nur die Daten zurück, welche im Query Cache prefetched sind. Er löst nie selbst einen fetch aus.
    queryKey: getArticleIndividualSpecialDeliveryDatesQueryKey(mainArticleId)
  });

  const specialDeliverDateData = specialDeliverDateQuery.data;
  const specialNextDeliveryDate = specialDeliverDateData?.specialDeliveryDateInfo ?? null;

  return { specialNextDeliveryDate };
}
