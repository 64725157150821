import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from 'mdi-material-ui/Information';
import { Theme, Typography, Grid, Divider } from '@mui/material';
import { Article } from '../../../shared/articles/models/article';
import { formatDate } from '../../../../util/dateUtils';
import { makeStyles } from 'tss-react/mui';
import { observer } from 'mobx-react-lite';
import { useArticleIndividualSpecialDeliveryDateInfo } from '../../../../features/search/data-access/useArticleIndividualSpecialDeliveryDateInfo';

export interface IArticleDetailSpecialNextDeliveryDateProps {
  article: Article;
}

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      marginBottom: theme.spacing(1),
      minHeight: 60
    },
    warning: {
      color: '#f0ad4e'
    },
    title: {
      width: 200,
      minWidth: 200
    }
  };
});

const ArticleDetailSpecialNextDeliveryDate = observer(({ article }: IArticleDetailSpecialNextDeliveryDateProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  let { specialNextDeliveryDate } = useArticleIndividualSpecialDeliveryDateInfo(article.mainArticleId);

  if (!specialNextDeliveryDate && !article.articleOrZzArticle.specialNextDeliveryDate) {
    return null;
  }
  specialNextDeliveryDate = article.articleOrZzArticle.specialNextDeliveryDate!;

  return (
    <>
      <Grid container alignItems={'center'} sx={{ minHeight: '60', margin: 0 }} direction={'row'} spacing={1} flexGrow={1}>
        <Grid item sx={{ width: '200px', minWidth: '200px' }}>
          <Typography variant={'subtitle2'}> {t('Frühester Liefertermin')}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'body2'}>{formatDate(new Date(specialNextDeliveryDate))}</Typography>
            </Grid>
            <Grid item>
              <InfoIcon sx={{ color: '#f0ad4e' }} fontSize="small" />
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>{t('KEIN RÜCKGABERECHT')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
});

export default ArticleDetailSpecialNextDeliveryDate;
